/* eslint-disable react/destructuring-assignment */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AttendeeNoteButton from './DataTable/components/AttendeeNoteButton';

const CreateTableData = (setNoteModalVisibility) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'client',
        key: 'client',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'dropdown',
        isTableColumn: true,
      },
      {
        Header: 'Event Name',
        accessor: 'eventName',
        key: 'eventName',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Service Provider Name',
        accessor: 'serviceProviderName',
        key: 'serviceProviderName',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Event Type',
        accessor: 'eventTypeName',
        key: 'eventTypeName',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Consent Status',
        accessor: 'consentStatus',
        key: 'consentStatus',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Consent Status Year',
        accessor: 'consentStatusYear',
        key: 'consentStatusYear',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        key: 'notes',
        disableGlobalFilter: true,
        // filterType: 'dropdown',
        canSort: false,
        Cell: data => <AttendeeNoteButton setModalVisbility={setNoteModalVisibility} {...data.row.original} />,
        isTableColumn: true,
      },
      {
        Header: 'Client Event ID',
        accessor: 'clientEventId',
        key: 'clientEventId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Date of Event',
        accessor: 'startDate',
        key: 'startDate',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Date of Event From',
        accessor: 'dateOfEventFrom',
        key: 'dateOfEventFrom',
        filterType: 'date',
        isTableColumn: false,
      },
      {
        Header: 'Date of Event To',
        accessor: 'dateOfEventTo',
        key: 'dateOfEventTo',
        filterType: 'date',
        isTableColumn: false,
      },
      {
        Header: 'Attendee Type',
        accessor: 'attendeeType',
        key: 'attendeeType',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Service Provider Email Address',
        accessor: 'serviceProviderEmailAddress',
        key: 'serviceProviderEmailAddress',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Client Provider ID',
        accessor: 'clientProviderId',
        key: 'clientProviderId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Current Status',
        accessor: 'currentStatus',
        key: 'currentStatus',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Contracted Fee',
        accessor: 'contractedFee',
        key: 'contractedFee',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Confirmed Fee',
        accessor: 'confirmedFee',
        key: 'confirmedFee',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses Allowable',
        accessor: 'expensesAllowable',
        key: 'expensesAllowable',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses Total',
        accessor: 'expensesTotal',
        key: 'expensesValue',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'VAT Value',
        accessor: 'vatValue',
        key: 'vatValue',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Total Payment',
        accessor: 'totalPayment',
        key: 'totalPayment',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payee Name',
        accessor: 'payeeName',
        key: 'payeeName',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Invoice Required?',
        accessor: 'invoiceRequired',
        key: 'invoiceRequired',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Invoice Submitted',
        accessor: 'invoiceSubmittedDate',
        key: 'invoiceSubmittedDate',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Meeting Organiser Email Address',
        accessor: 'meetingOrganiserEmailAddress',
        key: 'meetingOrganiserEmailAddress',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payment Approver Email Address',
        accessor: 'paymentApproverEmailAddress',
        key: 'paymentApproverEmailAddress',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Finance Contact Name (if required)',
        accessor: 'financeContactName',
        key: 'financeContactName',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Finance Contact Email Address (if required)',
        accessor: 'financeContactEmailAddress',
        key: 'financeContactEmailAddress',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'PAYCE Event ID',
        accessor: 'payceEventId',
        key: 'payceEventId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'PAYCE Attendee ID',
        accessor: 'payceEventAttendeeId',
        key: 'payceEventAttendeeId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Cost Centre',
        accessor: 'costCentre',
        key: 'costCentre',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Organisation Type',
        accessor: 'organisationType',
        key: 'organisationType',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Service Provider Type',
        accessor: 'serviceProviderType',
        key: 'serviceProviderType',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Services Received Date',
        accessor: 'servicesReceivedCompletedOnUtc',
        key: 'servicesReceivedCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses Submitted Date',
        accessor: 'expensesSubmittedCompletedOnUtc',
        key: 'expensesSubmittedCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payment Approved Date',
        accessor: 'approvedCompletedOnUtc',
        key: 'approvedCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Contract Approved Date',
        accessor: 'preApprovedCompletedOnUtc',
        key: 'preApprovedCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Sent for Payment Date',
        accessor: 'sentForPaymentCompletedOnUtc',
        key: 'sentForPaymentCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payment Blocked',
        accessor: 'blockPaymentResult',
        key: 'blockPaymentResult',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Delegate Accepted Date',
        accessor: 'delegateAcceptedCompletedOnUtc',
        key: 'delegateAcceptedCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Paid Date',
        accessor: 'paidCompletedOnUtc',
        key: 'paidCompletedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses Unlocked Date',
        accessor: 'expensesUnlockedOnUtc',
        key: 'expensesUnlockedOnUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Contract Created Date',
        accessor: 'createdDateUtc',
        key: 'createdDateUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Contract Issued Date',
        accessor: 'unsignedDateIssuedUtc',
        key: 'unsignedDateIssuedUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Contract Signed Date',
        accessor: 'signedDateIssuedUtc',
        key: 'signedDateIssuedUtc',
        disableGlobalFilter: true,
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns.filter(x => x.isTableColumn), filters: columns, tableRowsData: [] };

  return reactTableData;
};

export const CreatePaymentTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date Paid',
        accessor: 'datePaidUtc',
        key: 'datePaidUtc',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'date',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payment Year',
        accessor: 'paymentYear',
        key: 'paymentYear',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Client',
        accessor: 'client',
        key: 'client',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'dropdown',
        isTableColumn: true,
      },
      {
        Header: 'Service Organisation Name',
        accessor: 'serviceOrgName',
        key: 'serviceOrgName',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        key: 'lastName',
        disableGlobalFilter: true,
        sticky: 'left',
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        key: 'firstName',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        key: 'email',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Consent',
        accessor: 'consentStatus',
        key: 'consentStatus',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'External data source ID',
        accessor: 'externalDataSourceId',
        key: 'externalDataSourceId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Engagement Name',
        accessor: 'eventName',
        key: 'eventName',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Client Event ID',
        accessor: 'clientEventId',
        key: 'clientEventId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'PAYCE Event ID',
        accessor: 'payceEventId',
        key: 'payceEventId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Client Attendee ID',
        accessor: 'clientAttendeeId',
        key: 'clientAttendeeId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Payce Attendee ID',
        accessor: 'payceAttendeeId',
        key: 'payceAttendeeId',
        disableGlobalFilter: true,
        filterType: 'text',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Attendee Type',
        accessor: 'attendeeType',
        key: 'attendeeType',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Attendee Sub-type',
        accessor: 'attendeeSubtype',
        key: 'attendeeSubtype',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Imported',
        accessor: 'imported',
        key: 'imported',
        disableGlobalFilter: true,
        filterType: 'dropdown',
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Fees',
        accessor: 'fees',
        key: 'fees',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses for Service',
        accessor: 'expensesForService',
        key: 'expensesForService',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Registration Fees',
        accessor: 'registrationFees',
        key: 'registrationFees',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Expenses for Attending',
        accessor: 'expensesForAttending',
        key: 'expensesForAttending',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Grants and Donations',
        accessor: 'grantsAndDonations',
        key: 'grantsAndDonations',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Sponsorship',
        accessor: 'sponsorship',
        key: 'sponsorship',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'VAT',
        accessor: 'vat',
        key: 'vat',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Income tax',
        accessor: 'incomeTax',
        key: 'incomeTax',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
      {
        Header: 'Total',
        accessor: 'total',
        key: 'total',
        disableGlobalFilter: true,
        filterType: 'text',
        Cell: row => <div style={{ textAlign: 'right' }}>{row.value}</div>,
        canSort: true,
        isTableColumn: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns.filter(x => x.isTableColumn), filters: columns, tableRowsData: [] };

  return reactTableData;
};

export default CreateTableData;
